import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dimmer, Grid, Loader, Icon, Modal, Button, Header } from 'semantic-ui-react';
import api from '../../api';
import { setAuth } from "../../redux/auth";
import "./Login.css";
import Toast from "../common/Toast";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";

const Login = (props, { admin }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const location = useLocation();

    const history = useHistory();

    const auth = useSelector(state => state.auth);

    const [loadingActive, setLoadingActive] = useState();

    const [messageError, setMessageError] = useState();

    const [mustShowCaptcha, setMustShowCaptcha] = useState(false);

    const [showFailedLogin, setShowFailedLogin] = useState(false);

    const [showMaintenance, setShowMaintenance] = useState(false);
    const [messageMaintenance, setMessageMaintenance] = useState();

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            username: undefined,
            password: undefined,
            captcha: undefined
        }
    });

    function closeFailedLogin() {
        setShowFailedLogin(false);
    }

    function actionButtonSubmit() {
        let values = getValues();
        dispatch({ type: "auth/setUsername", payload: values.username });
        dispatch({ type: "auth/setPassword", payload: values.password.trim() });

        setLoadingActive(true)
	setShowFailedLogin(false);
        let body = { Username: values.username, Password: values.password.trim() };
        if (mustShowCaptcha) {
            body.ChallengeResponse = {
                "Type": "CAPTCHA",
                "ReCAPTCHAResponse": { "Answer": values.captcha }
            }
        }
        api.userLoginPost(body).then((resp) => {
            api.setSessionToken(resp.data.SessionToken);
            window.localStorage.removeItem('loggedInAdmin');
            window.localStorage.setItem("SessionToken", resp.data.SessionToken);
            window.localStorage.setItem('username', values.username);
            window.localStorage.setItem('userFirstName', resp.data.WelcomeName);
            window.localStorage.setItem('loggedIn', "true");
            dispatch(setAuth({ token: resp.data.SessionToken }));
            setLoadingActive(false);
        }).catch((err) => {
            dispatch({ type: "auth/setUsername", payload: '' });
            dispatch({ type: "auth/setPassword", payload: '' });
            setValue('username', undefined);
            setValue('password', undefined);
            setValue('captcha', undefined);
            if (window.grecaptcha && mustShowCaptcha) {
                window.grecaptcha.reset();
            }

            api.logout();
            setMustShowCaptcha(false);
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                if (err.response.data.ChallengeRequest) {
                    setMustShowCaptcha(true);
                }
		if (err.response.data.message === 'Bad username or password.') {
	            setShowFailedLogin(true)
		} else {
                    setMessageError(err.response.data.message)
                    props.setOpenToast(true);
	        }
			
            }
            else {
                setMessageError(err.message)
                props.setOpenToast(true);
            }
        })
    }

    const solveCaptcha = (response) => {
        setValue('captcha', response);
    }

    React.useEffect(() => {
        let query = new URLSearchParams(location.search);
        if (query.get('sessionExpired')) {
            setMessageError(t('sessionExpiredMessage'));
            props.setOpenToast(true);
        }
        api.setSessionToken('-');
        loadWindowCheck();
    }, []);

    
    const loadWindowCheck = () => {
        setLoadingActive(true);
        api.checkWindows().then((resp) => {
            if (resp.data?.code === 'Closed') {
                setShowMaintenance(true);
                setMessageMaintenance(resp.data?.message);
            }
            setLoadingActive(false);
        }).catch((err) => {
            console.log(err)
            setLoadingActive(false);
        });
    }


    React.useEffect(() => {
        if (auth.authenticated) {
            history.replace("/dashboard");
        }
    }, [auth.authenticated]);

    return (
        <form className="forgotUsernameForm" onSubmit={handleSubmit(actionButtonSubmit)}>
            <Grid columns={1} rows={5} className="body-grid-login">

                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }

                {props.openToast &&
                    <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
                }
	    { showMaintenance &&
	    <>
			    <Grid.Row centered>
			    <p className="maintenance-message">{messageMaintenance}</p>
			    </Grid.Row>
			    </>
	    }

	    { !showMaintenance &&
	    <>

                <Grid.Row centered>
                    <span className="login-title">{t("titleSignin")}</span>
                </Grid.Row>

	    <Modal open={showFailedLogin} size='mini'>
	        <Header icon="exclamation" content={t('failed-login-header')} />
	        <Modal.Content>
	            <p>{t('failed-login-message')}</p>
	            {
		        mustShowCaptcha ? 
	                <p>{t('failed-login-remind-1')}<Link to={"/forgot-username"}>{t('failed-login-remind-2')}</Link>{t('failed-login-remind-3')}<Link to={"/forgot-password"}>{t('failed-login-remind-4')}</Link>{t('failed-login-remind-5')}</p> : null
		    }
	        </Modal.Content>
	        <Modal.Actions><Button onClick={closeFailedLogin}>Close</Button></Modal.Actions>
	    </Modal>

                <Grid.Row>
                    <label className="letter-body-login" htmlFor="username">
                        {t("username")} <Icon className="mandatoryField" name='asterisk' />
                    </label>
                    <input aria-label={t("username")} type="text" id="username" name="username" ref={register({ required: true })} className="input-login" />
                    <Link className="letter-forgot-login" to={"/forgot-username"}>
                        {t("forgotUsername")}
                    </Link>
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-login" htmlFor="password">
                        {t("password")} <Icon className="mandatoryField" name='asterisk' />
                    </label>
                    <input aria-label={t("password")} type="password" ref={register({ required: true })} id="password" name="password" className="input-login" />
                    <Link className="letter-forgot-login" to={"/forgot-password"}>
                        {t("forgotPassword")}
                    </Link>
                </Grid.Row>

                {
                    mustShowCaptcha ?
                        <Grid.Row className="recaptcha-create-account text-center" centered>
                            <input aria-label='captcha' type="hidden" name="captcha" ref={register({ required: true })}></input>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA}
                                onChange={solveCaptcha}
                            />
                        </Grid.Row>
                        : null
                }

                <Grid.Row centered>
                    <button className="button-circle-login" type="submit" name="login" id="login">
                        {t("login")}
                    </button>

                    <Link className="letter-cancel-login" to={"/home"}>
                        {t("cancel")}
                    </Link>
                </Grid.Row>

                <Grid.Row centered style={{ paddingBottom: '40px' }}>
                    <span className="letter-new-login">{t("new")}</span> <Link className="letter-create-account-login" to={"/register"}>{t("createAccount")}</Link>
                </Grid.Row>
			    </>
	    }
            </Grid>
        </form>
    );
};

export default Login;
